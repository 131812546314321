<template>
  <div class="itemBox" v-if="orderdetail" >
    <Breadcrumb :sn="orderdetail.id"></Breadcrumb>
    <el-row class="topInfo">
      <el-col :span="5" class="text-center topLeftInfo">
        <div class="orderSn">订单ID：{{ orderdetail.id }}</div>
        <h2>{{ orderdetail.status===1?"待支付":orderdetail.status===2?"待发货":orderdetail.status===3?"待收货":orderdetail.status===4?"已完成":orderdetail.status===4?"已完成":"已取消" }}</h2>
        <el-col class="text-center bottomInfo">
          <div v-show="orderdetail.status === 1" @click="jumpOrderPay">立即付款</div>
          <!-- <div
            v-show="
              orderdetail.status === 2 || orderdetail.status === 3 || orderdetail.status === 4
            "
            @click="jumpInvoice"
          >
            查看发票
          </div> -->
          <div   v-show="orderdetail.attachId"  @click="DownAttach(orderdetail.attachId)">
            下载发票
          </div>
          <div v-show="orderdetail.status === 1 "   @click="cancellationOrder" >
            取消订单
          </div>
          <div v-show="orderdetail.status === 3" @click="sureReceiveOrder">确认收货</div>
          <!-- !hasRefund && (orderdetail.status === 2 || orderdetail.status === 3 || orderdetail.status === 4) -->
          <!-- backType//订单是否有可售后商品。默认无    // 1：待支付，2待发货，3待收货，4已完成，5已取消-->
          <div v-show="backType && (orderdetail.status === 2 || orderdetail.status === 3 )" @click="jumpApplySales()">
            申请售后
          </div>
          <div v-show="orderdetail.status === 4 && orderdetail.isEvaluate==0"  @click="jumpEvaluateOrder(orderdetail.id)">
            去评价
          </div>

          <!-- orderdetail.status === 4 ||  -->
          <div v-show="orderdetail.status === 5" @click="delOrder">
            删除订单
          </div>
           <!-- <div v-if="hasRefund" @click="jumpAfterRefundDetail">
              查看售后
          </div> -->
        </el-col>
      </el-col>
      <el-col :span="19" class="topRightInfo">
        <StepLine v-if="timelistFlag" :timeList="timelist"></StepLine>
      </el-col>
    </el-row>

    <el-row class="mainInfo">
      <el-col :span="24">
        <TitleHeader msg="订单信息" :noBorder="true"></TitleHeader>
      </el-col>
      <el-col class="receiveInfo">
        <!-- {{ orderdetail.memberStore.shippingName}}  {{ orderdetail.memberStore.shippingMobile }}  {{ orderdetail.memberStore.shippingAddress }}-->
        <span>收货信息：</span><span>{{ orderdetail.consigneeName}}，</span>
        <span>{{ orderdetail.consigneeTel }}，</span>
        <span>{{ orderdetail.address}}</span>
      </el-col>
      <el-col class="orderInfo">
        <el-col class="orderInfotitle">订单信息</el-col>
        <el-col class="ctebt" :span="8"
          ><span>支付金额：</span
          ><span>{{ orderdetail.payableAmount }}元</span></el-col
        >
        <el-col class="ctebt" :span="8"
          ><span>支付时间：</span
          ><span>{{
             orderdetail.paymentTime ? orderdetail.paymentTime : "无"
          }}</span></el-col
        >
         <!-- {{orderdetail.paymentMethod}} -->
        <el-col class="ctebt" :span="8"   v-if="orderdetail.paymentMethod>0"
          ><span>支付方式:</span><span>{{ orderdetail.paymentMethod===1?"微信":orderdetail.paymentMethod===2?"支付宝":orderdetail.paymentMethod===3?"银联":orderdetail.paymentMethod===4?"余额":orderdetail.paymentMethod===5?"授信":"" }}</span></el-col
        >
        <el-col class="ctebt" :span="8"
          ><span>支付单号：</span
          ><span>{{
            orderdetail.transactionId ? orderdetail.transactionId : "无"
          }}</span></el-col
        >
        <el-col class="ctebt" :span="8"
          ><span>订单编号：</span
          ><span>{{ orderdetail.sn }}</span></el-col
        >
        <el-col class="ctebt" :span="8"
          ><span>备注：</span
          ><span>{{ orderdetail.note }}</span></el-col
        >
      </el-col>

      <el-col>
        <OrderTiltle></OrderTiltle>
        <el-col class="goodsInfo">
          <!--渲染商品详情   orderdetail -->
          <OrderShopInfo
            v-for="(item, index) in orderdetail.orderDetails"
            :key="index"
            :goodsInfo="item"
          >
          </OrderShopInfo>
        </el-col>
      </el-col>

      <!-- <el-col class="gift">
                <el-col :span="2">赠品</el-col>
                <el-col :span="22"><Gift></Gift></el-col> 
              </el-col> -->
    </el-row>

    <el-col class="moneyInfo">
      <div class="text-right">商品金额：<span>{{ orderdetail.originalAmount }}</span></div>
      <div class="text-right">
        单品优惠券：<span>{{
          orderdetail.couponDetailAmount > 0
            ? -orderdetail.couponDetailAmount
            : 0
        }}</span>
      </div>
      <div class="text-right">
        未购优惠券：<span>{{
          orderdetail.noBuyCouponAmount > 0 ? -orderdetail.noBuyCouponAmount : 0
        }}</span>
      </div>
       <div class="text-right">
        多品优惠券：<span>{{
          orderdetail.specCouponAmount > 0 ? -orderdetail.specCouponAmount : 0
        }}</span>
      </div>
      <div class="text-right">
        订单优惠券：<span>{{
          orderdetail.couponAmount > 0 ? -orderdetail.couponAmount : 0
        }}</span>
      </div>
      <div class="text-right">促销折扣：<span>-{{orderdetail.promotionAmount}}</span></div>
      <div class="text-right">
        运费：<span>{{ orderdetail.freight }}</span>
      </div>
      <div class="text-right">
        实付订单金额：<span class="amount">{{
          orderdetail.payableAmount | capitalize
        }}</span>
      </div>
    </el-col>
  </div>
</template>

<script>
import {CheckRefund,cancelOrder,SureReceiveOrder, removeOrder,OrderDetail ,TimeList} from "api/order.js";
const Breadcrumb = () => import("components/common/Breadcrumb.vue");
const TitleHeader = () => import("components/common/TitleHeader.vue");
const OrderTiltle = () => import("components/order/OrderTiltle.vue");
const OrderShopInfo = () => import("components/order/OrderShopInfo.vue");
const StepLine =()=>import("components/order/StepLine.vue")
// const Gift = ()=>import("components/order/Gift.vue");

export default {
  name: "OrderDetail",
  data() {
    return {
      //金额
      // money: {
      //   total: 1000,
      //   discountAmount: 400,
      //   freight: 300,
      //   pid: 1100,
      // },
      // 控制步骤条及填写条目的显示 1,2,3,5
      num:1,
      //商品列表数据
      orderdetail: "",
      //订单流程
      timelist:'',
      // 是否有售后
      hasRefund:'',
      timelistFlag:false,
      backType:false    //订单是否有可售后商品。默认无
    };
  },
  methods: {
     // 下载发票
     DownAttach(PDFhref){
        PDFhref.split(',').forEach(item=>{
          console.log(item)
           const link = document.createElement("a");
          link.href =item;
          document.body.appendChild(link);
          link.click();
        })
    },
    // 评价
    jumpEvaluateOrder(orderId){
      this.$router.push({ path: "/EvaluateOrder", query: { orderId: orderId } });
    },
    // 查看售后
    jumpAfterRefundDetail(){
       // 提交成功，跳转售后列表页面
        this.$router.push({name:"AfterRefund", query: { ids: sessionStorage.getItem("orderId") } })
      //  sessionStorage.setItem("orderId",this.orderdetail.id)
            // this.$router.push({name:"AfterRefundDetail"})
    },
    // 立即付款
    jumpOrderPay(){
      sessionStorage.setItem("orderId",this.orderdetail.id)
        this.$router.push({name:"OrderSumbit"})
    },
    jumpInvoice() {
      sessionStorage.setItem("orderId",this.orderdetail.id)
      sessionStorage.setItem("orderSn",this.orderdetail.sn)
      this.$router.push({ name: "MyOrderInvoice" });
    },
    jumpApplySales() {
      sessionStorage.setItem("orderId",this.orderdetail.id)
      // alert(this.orderdetail.sn)
       sessionStorage.setItem("orderSn",this.orderdetail.sn)
      this.$router.push({ name: "ApplySales" });
    },
    //确认收货
    sureReceiveOrder(){
        let ids={
          ids:this.orderdetail.id
        }
        SureReceiveOrder(ids).then(data=>{
          if(data.data.code==200){
            this.$message({
              message: data.data.msg,
              type: 'success'
            });
            this.$router.push({ name: "MyOrder" });
          }
        })
    },
    //取消订单
    cancellationOrder(){
      let ids={
        ids:this.orderdetail.id
      }
      cancelOrder(ids).then(data=>{
        if(data.data.code==200){
          this.$message({
            message: data.data.msg,
            type: 'success'
          });
          this.$router.push({ name: "MyOrder" });
        }
      })
    },
    // 删除订单
    delOrder() {
      let ids={
        ids:this.orderdetail.id
      }
      removeOrder(ids).then(data=>{
        if(data.data.code==200){
          this.$message({
            message: data.data.msg,
            type: 'success'
          });
          this.$router.push({ name: "MyOrder" });
        }
        
      })
    },
    
    getOrderDetail() {
       // 显示loading
   this.$loading({ fullscreen: true ,background:'#ffffff45'})
        let that=this;
      OrderDetail(this.$route.query.orderId).then((res) => {
        that.orderdetail = res.data.data;
      this.orderdetail.orderDetails.forEach(item => {
          //  0  不可售后     1  可售后
          if(item.isCan===1){
            this.backType=true
          }
     });


        // 订单操作时间流程
        TimeList(this.orderdetail.id).then(data=>{
           that.timelist=data.data.data
           that.timelistFlag=true
        })
           // 关闭loading
          this.$loading().close();
      });
    },
    CheckRefundFun(){
      let orderId={
        orderId:this.$route.query.orderId
      }
      CheckRefund(orderId).then((res) => {
        if(res.data.code===400){
            this.hasRefund=false
        }else{
            this.hasRefund=true
        }
      })
    }
  },
  created() {
    this.getOrderDetail();
    this.CheckRefundFun();
  },
  components: {
    Breadcrumb,
    TitleHeader,
    OrderTiltle,
    OrderShopInfo,
    StepLine
    // Gift
  },
};
</script>

<style lang="less" scoped>
@import "~style/index.less";
/deep/.el-step__icon{
  width: 60px;
  height: 60px;
}
/deep/.el-col .titbox{
  padding: 0!important;
}
.topInfo {
  margin-bottom: 20px;
  background: #fff;

  .topLeftInfo {
    font-size: 12px;
    color: #666;
    line-height: 20px;
    height: 100%;
    padding: 23px 20px 20px;
    border-right: 1px solid #eee;
    .orderSn {
      color: #999;
      font-size: 13px;
      line-height: 18px;
    }
    h2 {
      padding-top: 20px;
      padding-bottom: 20px;
      font-size: 22px;
      color: #fa6400;
      font-weight: 600;
    }
    .bottomInfo {
      // padding-top: 32px;
      & > div {
        width: 110px;
        height: 34px;
        background: #f0f0f0;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        border: 1px solid #ccc;
      }
      & > div:hover {
        color: @theme;
        background: #f0716f;
        border: 1px solid @theme;
      }
    }
  }
  .topRightInfo {
    padding: 63px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.mainInfo {
  background: #fff;
  padding: 20px;
  .receiveInfo {
    border-bottom: 1px solid #f0f0f0;
    padding-top: 30px;
    padding-bottom: 30px;
    & > span {
      .fonts();
      color: #333;
      margin-left: 13px;
    }
    & > span:first-child {
      font-weight: bold;
    }
  }
  .orderInfo {
    margin-top: 30px;
    font-size: 14px;
    padding-left: 13px;
    padding-bottom: 30px;
    .fonts();
    color: #333;
    .orderInfotitle {
      font-weight: bold;
      padding-bottom: 5px;
    }
    .ctebt {
      padding-top: 15px;
      span:first-child {
        font-weight: 500;
      }
    }
  }
  .goodsInfo {
    border-left: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
  }
  .gift {
    border: 1px solid #eee;
    padding: 10px;
    display: flex;
    align-items: center;
  }
}

.moneyInfo {
  padding: 25px 58px;
  background: #fff;
  .fonts();
  color: #666;
  font-size: 14px;
  & > div {
    padding-bottom: 10px;
    & > span {
      display: inline-block;
      width: 130px;
      text-align: right;
    }
    .amount {
      color: @theme;
      font-weight: bold;
    }
  }
}

/deep/.el-step__title.is-success,
/deep/.el-step__description.is-success {
  color: #fa6400;
}
/deep/.el-step__head.is-success {
  color: #fa6400;
  border-color: #fa6400;
}
.itemBox{
  width: 1200px;
  margin: 0 auto;
}
</style>
